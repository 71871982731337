<template>
	<Html lang="IT-it">
		<ClientOnly>
			<NuxtLayout>
				<NuxtPage />
			</NuxtLayout>
		</ClientOnly>
		<UNotifications />
	</Html>
</template>

<script setup lang="ts">
const colorMode = useColorMode();
colorMode.value = 'light';
</script>
