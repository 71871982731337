export default defineAppConfig({
	ui: {
		primary: 'stone',
		modal: {
			default: {
				width: 'sm:max-w-2xl'
			}
		},

		formGroup: {
			label: {
				wrapper: 'flex content-center items-center justify-between',
				base: 'block text-gray-700 dark:text-gray-200'
			}
		}
	},

	icons: {
		dynamic: true
	}
});
